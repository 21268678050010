import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import ResponseModal from "../../components/ResponseModal";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData } = useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Sell Data Plans - DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    purchaseData();
  };

  const planTypes = ["SME Data", "Normal Data", "Corporate Data"];

  useEffect(() => {
    if (!tempData.dataPlans) {
      const plans = businessData.data_plans.filter(
        (dataPlan) => dataPlan.status * 1 === 0
      );
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "dataPlans", value: plans },
      });
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "dataPlan", value: plans[0].id },
      });
    }

    if (!formData.network) {
      formDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "network", value: "MTN" },
      });
      filterNetwork({ target: { value: "MTN" } });

      formDataDispatch({
        type: "SET_FORM_DATA",
        data: {
          name: "phoneStats",
          value: {
            total: 0,
            valid: [],
            invalid: [],
          },
        },
      });
    }
  }, []);

  const filterNetwork = (e) => {
    let dataPlans = businessData.data_plans;
    dataPlans = dataPlans.filter(
      (dataPlan) =>
        dataPlan.network === e.target.value && dataPlan.status * 1 === 0
    );
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "dataPlans", value: dataPlans },
    });

    if (dataPlans[0]) {
      formDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "dataPlan", value: dataPlans[0].id },
      });
    }
  };

  const processPhoneNumbers = (input) => {
    const numbers = input
      .split(/\r?\n|,/)
      .map((n) => n.trim())
      .filter((n) => n.length > 0);

    const valid = numbers.filter((n) => /^[0-9]{11}$/.test(n));
    const invalid = numbers.filter((n) => !/^[0-9]{11}$/.test(n));

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: {
        name: "phoneStats",
        value: {
          total: numbers.length,
          valid,
          invalid,
        },
      },
    });
  };

  const purchaseData = () => {
    const phoneNumbers = formData.phone_numbers.split(",").map((n) => n.trim());

    const allValid = phoneNumbers.every((n) => /^[0-9]{11}$/.test(n));
    if (!allValid) {
      tempDataDispatch({
        type: "SET_ERROR",
        data: "One or more phone numbers are invalid. All numbers must be 11 digits.",
      });
      return;
    }

    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Selling data...", isLoading: true },
    });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);

    const urlencoded = new URLSearchParams();
    urlencoded.append("source", "WEB");
    urlencoded.append("plan_id", String(formData.dataPlan));
    urlencoded.append("phone_numbers", formData.phone_numbers);
    urlencoded.append("payment_method", "ADMIN");

    fetch(localStorage.getItem("apiURL") + "data_purchase", {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    })
      .then((response) => response.text())
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });

        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "history",
              value: { history: props.history, url: "/" },
            },
          });

          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "clearData", value: true },
          });
        } else if (data.message === "Token Expired") {
          props.history.push("/signout");
        } else if (data.errors) {
          const errorString = Object.values(data.errors).join(", ");
          tempDataDispatch({ type: "SET_ERROR", data: errorString });
        } else {
          tempDataDispatch({ type: "SET_ERROR", data: data.message });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return !tempData.dataPlans ? (
    <></>
  ) : (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center">
          <Col xl="10" sm="12" className="p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0">
                    <b>Sell Data</b>
                  </p>
                  <hr className="border-primary" />
                </div>
                <Row>
                  <Col md="6">
                    {tempData.error && (
                      <Alert color="danger">{tempData.error}</Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                      <p className="text-left mb-0">
                        <b>Network</b>
                      </p>
                      <select
                        className="form-control mb-2"
                        value={formData.network}
                        name="network"
                        onChange={(e) => {
                          formOnChange(e);
                          filterNetwork(e);
                        }}
                      >
                        <option value="MTN">MTN</option>
                        <option value="GLO">GLO</option>
                        <option value="AIRTEL">AIRTEL</option>
                        <option value="ETISALAT">ETISALAT</option>
                      </select>

                      <p className="text-left mb-0 mt-2">
                        <b>Data Plan</b>
                      </p>
                      <select
                        className="form-control mb-2"
                        required
                        value={formData.dataPlan}
                        name="dataPlan"
                        onChange={formOnChange}
                      >
                        {tempData.dataPlans &&
                          tempData.dataPlans.map((dataPlan) => (
                            <option key={dataPlan.id} value={dataPlan.id}>
                              {dataPlan.name || dataPlan.master_name} ---{" "}
                              {planTypes[dataPlan.type]}
                            </option>
                          ))}
                      </select>

                      <Label for="phone_numbers">
                        <b>
                          Phone Number(s){" "}
                          <i>
                            Enter phone numbers, separated by commas or new
                            lines
                          </i>
                        </b>
                      </Label>
                      <textarea
                        id="phone_numbers"
                        name="phone_numbers"
                        className="form-control mb-2"
                        rows="5"
                        placeholder="Enter phone numbers, separated by commas or new lines"
                        value={formData.phone_numbers}
                        onChange={(e) => {
                          const value = e.target.value;
                          formDataDispatch({
                            type: "SET_FORM_DATA",
                            data: { name: "phone_numbers", value },
                          });
                          processPhoneNumbers(value);
                        }}
                      />

                      <Button
                        color="secondary"
                        className="mb-3"
                        onClick={(e) => {
                          e.preventDefault();
                          const raw = formData.phone_numbers || "";
                          const sanitized = raw
                            .split(/\r?\n|,/)
                            .map((x) => x.trim())
                            .filter((x) => x.length > 0)
                            .join(",");
                          formDataDispatch({
                            type: "SET_FORM_DATA",
                            data: { name: "phone_numbers", value: sanitized },
                          });
                          processPhoneNumbers(sanitized);
                        }}
                      >
                        Sanitize
                      </Button>

                      {formData.phoneStats.total > 0 && (
                        <div className="mb-3">
                          <p>
                            <b>Phone Numbers Preview</b>
                          </p>
                          <p>Total: {formData.phoneStats.total}</p>
                          <p className="text-success">
                            Valid ({formData.phoneStats.valid.length}):{" "}
                            {formData.phoneStats.valid.join(", ")}
                          </p>
                          {formData.phoneStats.invalid.length > 0 && (
                            <p className="text-danger">
                              Invalid ({formData.phoneStats.invalid.length}):{" "}
                              {formData.phoneStats.invalid.join(", ")}
                            </p>
                          )}
                        </div>
                      )}

                      <Button
                        className="btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Sell Data
                      </Button>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
