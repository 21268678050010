import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const fileInput = document.querySelector("#image1");
  const fileInput2 = document.querySelector("#image2");
  document.title = "Ad Images";

  const handleSubmit = (e, imageNo) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Uploading Image...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value: false },
    });
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var formData = new FormData();
    formData.append("image1", fileInput.files[0]);
    formData.append("image2", fileInput2.files[0]);
    formData.append("delete" + imageNo, "true");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };
    fetch(localStorage.getItem("apiURL") + "ad_image", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  // useEffect(() => {
  //   if (!tempData.dataPlansLoaded) {
  //      tempDataDispatch({
  //       type: "SET_FORM_DATA",
  //       data: { name: "dataPlansLoaded", value: true },
  //     });
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Ad Images</b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <div className="account-card-content mt-2 ">
                  {formData.error && (
                    <Alert color="danger">{formData.error}</Alert>
                  )}

                  <Row className="m-0">
                    <Col className="m-0" xl="6" xs="12">
                      <p>Image 1</p>
                      <AvForm
                        className="form-horizontal mt-1 mb-2"
                        onValidSubmit={(e) => handleSubmit(e, 1)}
                      >
                        <input
                          type="file"
                          className="w-full px-4 py-2"
                          label="image 1"
                          name="image1"
                          id="image1"
                          onChange={(e) =>
                            formDataDispatch({
                              type: "SET_FORM_DATA",
                              data: {
                                name: "image1",
                                value: e.target.files[0],
                              },
                            })
                          }
                          required
                        />
                        <Button
                          className="btn-primary w-md mt-2 waves-effect waves-light "
                          type="submit"
                        >
                          Upload
                        </Button>
                      </AvForm>
                    </Col>
                    <Col className="m-0" xl="6" xs="12">
                      <h6>Preview</h6>
                      <Card className="mini-stat text-dark shadow">
                        <CardBody>
                          <img
                            style={{ maxHeight: "100px" }}
                            src={
                              "https://api.datanow.ng:2050/storage/adImages/" +
                              businessData.business.business_id +
                              "ad1.png"
                            }
                            alt="loading"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row className="m-0">
                    <Col className="m-0" xl="6" xs="12">
                      <p>Image 2</p>
                      <AvForm
                        className="form-horizontal mt-1 mb-2"
                        onValidSubmit={(e) => handleSubmit(e, 2)}
                      >
                        <input
                          type="file"
                          className="w-full px-4 py-2"
                          label="Image 2"
                          name="image2"
                          id="image2"
                          onChange={(e) =>
                            formDataDispatch({
                              type: "SET_FORM_DATA",
                              data: {
                                name: "image2",
                                value: e.target.files[0],
                              },
                            })
                          }
                        />
                        <Button
                          className="btn-primary w-md mt-2 waves-effect waves-light "
                          type="submit"
                        >
                          Upload
                        </Button>
                      </AvForm>
                    </Col>
                    <Col className="m-0" xl="6" xs="12">
                      <h6>Preview</h6>
                      <Card className="mini-stat text-dark shadow">
                        <CardBody>
                          <img
                            style={{ maxHeight: "100px" }}
                            src={
                              "https://api.datanow.ng:2050/storage/adImages/" +
                              businessData.business.business_id +
                              "ad2.png"
                            }
                            alt="loading"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
